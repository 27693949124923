import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import classes from './Footer.module.scss';


class Footer extends Component {


    render() {

        return (
            <div className={"container-fluid "+classes.Footer}>
                <div className={"container"}>


                    <div className="row">
                        <div className={"col-lg-3 "+classes.FooterCell}>
                            <a href="https://www.coopersurgical.com/privacy-statement/"
                               rel="noopener noreferrer"
                               target="_blank">Privacy Statement</a>
                        </div>
                        <div className={"col-lg-3 "+classes.FooterCell}>
                            <a href="https://www.coopersurgical.com/data-privacy-policy/"
                               rel="noopener noreferrer"
                               target="_blank">Data Privacy Policy</a>
                        </div>
                        <div className={"col-lg-2 "+classes.FooterCell}>
                            <a href="https://www.coopersurgical.com/cookie-policy/"
                               rel="noopener noreferrer"
                               target="_blank">Cookie Policy</a>
                        </div>
                        <div className={"col-lg-4 "+classes.FooterCell}>
                            <a href="https://www.coopersurgical.com/legal-statement/"
                               rel="noopener noreferrer"
                               target="_blank">Legal and Modern Slavery Act</a>
                        </div>
                    </div>
                    <div className="row">
                        {/*<div className={"col-lg-3 "+classes.FooterCell}>*/}
                        {/*    <a href="https://www.coopersurgical.com/site-terms-conditions/"*/}
                        {/*       rel="noopener noreferrer"*/}
                        {/*       target="_blank">Terms and Conditions</a>*/}
                        {/*</div>*/}
                        {/*<div className={"col-lg-3 "+classes.FooterCell}>*/}
                        {/*    <a href="https://www.coopersurgical.com/ccpa-privacy-policy/"*/}
                        {/*       rel="noopener noreferrer"*/}
                        {/*       target="_blank">California Privacy Policy</a>*/}
                        {/*</div>*/}
                        <div className={"col-lg-6 "+classes.FooterCell}>
                            <a href="https://www.coopersurgical.com/ccpa-common-request-form/"
                               rel="noopener noreferrer"
                               target="_blank">CCPA Common Request Form (California Residents only)</a>
                        </div>

                    </div>



                    {/*<div className="row">*/}
                        {/*<div className="col-12">*/}
                            {/*<ul>*/}
                                {/*<li><a href="https://www.coopersurgical.com/privacy-statement/" target="_blank">Privacy Statement</a></li>*/}
                                {/*<li><a href="https://www.coopersurgical.com/legal-statement/" target="_blank">Legal and Modern Slavery Act</a></li>*/}
                                {/*<li><a href="https://www.coopersurgical.com/data-privacy-policy/" target="_blank">Data Privacy Policy</a></li>*/}
                                {/*<li><a href="https://www.coopersurgical.com/cookie-policy/" target="_blank">Cookie Policy</a></li>*/}
                                {/*<li><a href="https://www.coopersurgical.com/site-terms-conditions/" target="_blank">Terms and Conditions</a></li>*/}
                                {/*<li><a href="https://www.coopersurgical.com/ccpa-privacy-policy/" target="_blank">California Privacy Policy</a></li>*/}
                                {/*<li><a href="https://www.coopersurgical.com/ccpa-common-request-form/" target="_blank">CCPA Common Request Form (California Residents only)</a></li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>

            </div>
        );
    }
}




export default withRouter(Footer);

