import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter}  from 'react-router-dom';

import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';

import miscReducer from './store/reducers/misc';
// import navigationReducer from './store/reducers/navigation';

import './index.css';

import App from './App';

import ScrollToTop from './components/UI/ScrollToTop/ScrollToTop';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper/ServiceWorkerWrapper';
import FirebaseAnalytics from './components/Firebase/FirebaseAnalytics';
import {getSiteConfig} from './config';

import smoothscroll from './shared/smooth-scroll-polyfil';

//
// Firebase App (the core Firebase SDK) is always required and must be listed first.
//
import * as firebase from "firebase/app";
import "firebase/analytics";

// import * as serviceWorker from './serviceWorker';


import './assets/css/styles.scss';

//
// Initialize the smooth scrolling polyfill.
// Used for window.scrollTo({'smooth'}) on Apple devices.
//
smoothscroll.polyfill();


//
// Initialize Firebase/Analytics
//
firebase.initializeApp(getSiteConfig().FIREBASE_CONFIG);
firebase.analytics();


//
// Setup Redux
//

const composeEnhancers = (process.env.NODE_ENV==='development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
// console.log(composeEnhancers);


const rootReducer = combineReducers({
    misc: miscReducer
    // navigation: navigationReducer
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);



const app = (

        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop>
                    <App/>
                </ScrollToTop>
                <FirebaseAnalytics/>
            </BrowserRouter>
        </Provider>

);


ReactDOM.render(
    <React.StrictMode>
        {app}
        <ServiceWorkerWrapper/>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//
// NOTE: This has been moved to: /components/ServiceWorkerWrapper/ServiceWorkerWrapper.js

//
// const swConfig = {
//     onUpdate(registration){
//         console.log("onUpdate", registration);
//
//
//     },
//
//     onSuccess(registration){
//         console.log("onSuccess", registration);
//     }
// };
//
//
// //serviceWorker.unregister();
// serviceWorker.register(swConfig);
//

//
// RJS-2021- DELETE AFTER LANGUAGE SUPPORT IS ADDED
//
// function getBrowserLocales(options = {}) {
//     const defaultOptions = {
//         languageCodeOnly: false,
//     };
//
//     const opt = {
//         ...defaultOptions,
//         ...options,
//     };
//
//     const browserLocales =
//         navigator.languages === undefined
//             ? [navigator.language]
//             : navigator.languages;
//
//     if (!browserLocales) {
//         return undefined;
//     }
//
//     return browserLocales.map(locale => {
//         const trimmedLocale = locale.trim();
//
//         return opt.languageCodeOnly
//             ? trimmedLocale.split(/-|_/)[0]
//             : trimmedLocale;
//     });
// }
//
//
// consol.log(getBrowserLocales)

