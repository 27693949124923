import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from "../../store/actions";

class ErrorBoundary extends React.Component {

    componentDidCatch(error, info) {

        this.props.onSetHasSystemError(true);

        console.log("ErrorBoundary.componentDidCatch", error, info)
    }

    goHome = () => {

        this.props.onSetHasSystemError(false);

        this.props.history.push("/");
    };


    reloadScreen = () => {

        this.props.onSetHasSystemError(false);

        window.location.reload();
    };

    render() {
        if (this.props.hasSystemError) {
            return (
                <div id="main">

                    <div className="container recent-view">

                        <h2 className="green-blue text-center">Warning</h2>

                        <p className="paragraphp5 text-center mt-4 mb-4">
                            An error has been detected.
                            <br/><br/>
                            Confirm you have internet access.
                            <br/><br/>
                            If the problem persists, restart the app.
                        </p>

                        <button className="btn btn-block btn-primary error-btn blue-btn mb-3" onClick={this.reloadScreen}>Reload screen.</button>

                        <button className="btn btn-block btn-primary error-btn blue-btn" onClick={this.goHome}>Go to the products home screen.</button>

                    </div>

                </div>
            );
        }

        return this.props.children;
    }
}



const mapStateToProps = (state) => {
    return {
        hasSystemError: state.misc.hasSystemError
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetHasSystemError: (hasSystemError) => {
            dispatch( actions.setHasSystemError(hasSystemError) );
        }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorBoundary));