import {formDate_1} from "../../shared/utility";
import {writeData,readAllData,supportsIndexedDB} from '../../indexeddb/indexeddb-utils';


const LOCALSTORAGE__RECENTLY_VIEWED_KEY = "recently-viewed";


let __allowRecentlyViewedSaved = true;

export const allowRecentlyViewedToBeSaved = (mode) => {
    __allowRecentlyViewedSaved = mode;
};



export const getRecentlyViewed = () => {

    return new Promise((resolve) => {

        if(supportsIndexedDB() ){

            readAllData("recentlyviewed")
                .then( (list) => {

                    const sortedList = list.sort((a, b) => b.timestamp - a.timestamp);

                    resolve(sortedList);
                })
                .catch( (err) => {
                    console.log('getRecentlyViewed.readAll(err):', err);
                });
        } else {

            let recentlyViewedStr = localStorage.getItem( LOCALSTORAGE__RECENTLY_VIEWED_KEY );

            let recentlyViewed = [];
            if(recentlyViewedStr){
                recentlyViewed = JSON.parse(recentlyViewedStr);
            }

            resolve(recentlyViewed);
        }
    });

};



export const addRecentlyViewed = (resourceId) => {

    if(!__allowRecentlyViewedSaved){
        return;
    }

    const now = new Date();

    const viewDate = formDate_1(now);

    const record = {resourceId:resourceId, viewed:viewDate, timestamp: now.getTime()};

    if(supportsIndexedDB() ){

        //
        // ** IndexedDB **
        //
        writeData('recentlyviewed', record);
    } else {

        //
        // ** Local Storage **
        //
        let recentlyViewedStr = localStorage.getItem( LOCALSTORAGE__RECENTLY_VIEWED_KEY );

        let recentlyViewed = [];
        if(recentlyViewedStr){
            recentlyViewed = JSON.parse(recentlyViewedStr);
        }

        // Remove existing resource
        recentlyViewed = recentlyViewed.filter(item => item.resourceId !== resourceId);


        recentlyViewed.splice(0, 0, record );


        // console.log(JSON.stringify(recentView));

        localStorage.setItem( LOCALSTORAGE__RECENTLY_VIEWED_KEY , JSON.stringify(recentlyViewed));
    }
};




