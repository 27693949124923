import * as actionTypes from './actionTypes';

export const setHasSystemError = (hasSystemError) => {
    return {
        type:actionTypes.SET_HAS_SYSTEM_ERROR,
        hasSystemError: hasSystemError
    }
};



export const setPlayingVideo = (playingVideo, playingVideoId, playingVideoResourceId) => {
    return {
        type:actionTypes.SET_PLAYING_VIDEO,
        playingVideo: playingVideo,
        playingVideoId: playingVideoId,
        playingVideoResourceId: playingVideoResourceId
    }
};
