import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.INSORB+"_"+id;
};


const data =
{
    id: PRODUCTS.INSORB,
    alt:"Insorb Skin Stapler",
    title:(<React.Fragment>INSORB<sup>&reg;</sup> Skin Stapler</React.Fragment>),
    productImages: [
            require('../assets/images/insorb/insorb-product-img.png'),
            require('../assets/images/insorb/insorb-product-img@2x.png'),
            require('../assets/images/insorb/insorb-product-img@3x.png')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:null,
                        subTitle: "Overview & Instructions",
                        description:(<React.Fragment>Overview of stapling process and benefits. Instructions for use, including final staple placement, avoiding external staple placement, tips and tricks.</React.Fragment>),
                        wistiaId:"4i32xh0kvl",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]

            }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"INSORB Surgical Stapler Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/INSORB Surgical Stapler Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_insorb-for-use.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_insorb-for-use@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_insorb-for-use@3x.jpg")
                ]
            }
        ]
    },
    externalProductId:"2030"
};


export default data;








