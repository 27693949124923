import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.MARA+"_"+id;
};


const data =
{
    id: PRODUCTS.MARA,
    name:"Mara",
    alt:"Mara",
    title:(<React.Fragment>Mara</React.Fragment>),
    productImages: [
            require('../assets/images/mara/img-mara-product.jpg'),
            require('../assets/images/mara/img-mara-product@2x.jpg'),
            require('../assets/images/mara/img-mara-product@3x.jpg')
        ],
    interactiveDemo: {
        images: [
            require('../assets/images/mara/interactive-demo/mara-demo.jpg'),
            require('../assets/images/mara/interactive-demo/mara-demo@2x.jpg')
        ],
        link:"https://cooper-mara.web.app/story.html",
    },
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:null,
                        subTitle:"Mara Water Vapor Ablation System – Product Animation Video",
                        description:null,
                        wistiaId:"hf5f44pgag",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v2"),
                        title:null,
                        subTitle:"Mara Water Vapor Ablation System – Healthcare Provider Training Video",
                        description:null,
                        wistiaId:"n5cxn9dpkz",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            // {
            //     isChapterBased:true,
            //     videos:[
            //         {
            //             resourceId:generateResourceId("vs1v3"),
            //             title:null,
            //             subTitle:"Mara Promotional Video",
            //             description:null,
            //             wistiaId:"r81nry4lwk",
            //             wistiaConfig:{
            //                 padding:"56.25% 0 0 0"
            //             }
            //         }
            //     ]
            // }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"IFU Mara Water Vapor Ablation System Model",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/mara/Mara Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/mara/doc-thumbs/mara-instructions-for-use.jpg"),
                    require("../assets/images/mara/doc-thumbs/mara-instructions-for-use@2x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d_2pg_brochure"),
                title:"Mara 2 Page Brochure",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/mara/Mara 2 Page Brochure.pdf",
                thumbnails:[
                    require("../assets/images/mara/doc-thumbs/mara-2-pg-brochure.jpg"),
                    require("../assets/images/mara/doc-thumbs/mara-2-pg-brochure@2x.jpg"),
                    require("../assets/images/mara/doc-thumbs/mara-2-pg-brochure@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d2"),
                title:"Mara 4 Page Brochure",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/mara/Mara 4 Page Brochure.pdf",
                thumbnails:[
                    require("../assets/images/mara/doc-thumbs/mara-4-pg-brochure.png"),
                    require("../assets/images/mara/doc-thumbs/mara-4-pg-brochure@2x.png"),
                    require("../assets/images/mara/doc-thumbs/mara-4-pg-brochure@3x.png")
                ]
            },
            {
                resourceId: generateResourceId("d4"),
                title:"Mara Operating Manual",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/mara/Mara Operator Manual.pdf",
                thumbnails:[
                    require("../assets/images/mara/doc-thumbs/mara-operators-manual@1x.jpg"),
                    require("../assets/images/mara/doc-thumbs/mara-operators-manual@2x.jpg"),
                    require("../assets/images/mara/doc-thumbs/mara-operators-manual@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d3"),
                title:"Mara Patient Brochure",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/mara/Mara Patient Brochure.pdf",
                thumbnails:[
                    require("../assets/images/mara/doc-thumbs/mara-patient-brochure.png"),
                    require("../assets/images/mara/doc-thumbs/mara-patient-brochure@2x.png"),
                    require("../assets/images/mara/doc-thumbs/mara-patient-brochure@3x.png")
                ]
            },
            {
                resourceId: generateResourceId("mara_patient_labeling"),
                title:"Mara Patient Labeling",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/mara/Mara Patient Labeling.pdf",
                thumbnails:[
                    require("../assets/images/mara/doc-thumbs/mara-patient-labeling.jpg"),
                    require("../assets/images/mara/doc-thumbs/mara-patient-labeling@2x.jpg")
                ]
            }
        ]
    },
    externalProductId:"GEN-16-050"
};


export default data;








