import React, {Component} from 'react';



//
// Use srcSet to handle image selection.
// For devices that don't support it, use the low res image.
//
class RetinaImage extends Component {

    render(){

        let srcSet = `${this.props.src[0]} 1x`;

        if( this.props.src.length===2 ){
            srcSet += `, ${this.props.src[1]} 2x`;
        }else if( this.props.src.length>2 ){
            srcSet += `, ${this.props.src[1]} 2x, ${this.props.src[2]} 3x`
        }

        return (
            <img className={this.props.className}
                 src={this.props.src[0]}
                 srcSet={srcSet}
                 alt={this.props.alt}/>
        );
    }
}


export default RetinaImage;