
// Version of the app.
export const APP_VERSION = "1.118";

// For deploying to dev/qa this must be set to true.
const FORCE_DEV_MODE_CONFIG = false;


export const getSiteConfig = ()=>{
    if(process.env.NODE_ENV==="development" || FORCE_DEV_MODE_CONFIG){
        //
        // Development config.
        //

        // console.log("Development config...");

        return {
            DOC_SITE_DEFAULT_URL: "https://cooper-poc-docs-f916a.web.app",
            DOC_SITE_REGISTER_URL:"https://cooper-poc-docs-f916a.web.app/register",
            GET_APP_VERION_SERVICE_URL: "https://cooper-poc.firebaseio.com/versionDetails.json",

            // GOOGLE_MAP_API_KEY:"",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyB1Ubr2KyjyIQ1P4Q1p4ieQrRta85vhYYU",
                authDomain: "cooper-poc-dev.firebaseapp.com",
                databaseURL: "https://cooper-poc-dev.firebaseio.com",
                projectId: "cooper-poc-dev",
                storageBucket: "cooper-poc-dev.appspot.com",
                messagingSenderId: "679624836361",          // For Firebase JS SDK v7.20.0 and later, measurementId is optional
                appId: "1:679624836361:web:0c14cb85ed65368f2cbfd8",
                measurementId: "G-937D3PRLRG"
            }

        }
    } else {
        //
        // Production Config
        //
        // console.log("Production config...")

        return {
            DOC_SITE_DEFAULT_URL: "https://inservice-docs.coopersurgical.com/",
            DOC_SITE_REGISTER_URL:"https://inservice-docs.coopersurgical.com/register",
            GET_APP_VERION_SERVICE_URL: "https://customer-responsiveness-pwa.firebaseio.com/versionDetails.json",

            // GOOGLE_MAP_API_KEY:"",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyCEBKpfKF9NrVcsGgTaJOVqg5hThKc_15U",
                authDomain: "customer-responsiveness-pwa.firebaseapp.com",
                databaseURL: "https://customer-responsiveness-pwa.firebaseio.com",
                projectId: "customer-responsiveness-pwa",
                storageBucket: "customer-responsiveness-pwa.appspot.com",
                messagingSenderId: "671349895597",
                appId: "1:671349895597:web:b5373f4437da7f76e93124",
                measurementId: "G-0SD9G5WX3H"
            }
        }
    }
};
