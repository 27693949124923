import React, { Component } from 'react';
import {Switch, Redirect} from 'react-router-dom';

import RouteWrapper from './hoc/RouteWrapper/RouteWrapper';
import Layout from './hoc/Layout/Layout';

import WistiaManager from './components/WistiaWrapper/WistiaManager';

import Products from './pages/Products/ProductsHome';
import DocumentDownloadInit from './components/DocumentDownloadInit/DocumentDownloadInit';

// See note below for: RecentVideo and RecentDocument
// This does not work either. Page loads but again React thinks the base directory is /recent.
// import RecentVideo from './pages/Recent/RecentVideo/RecentVideo';
// import RecentDocument from './pages/Recent/RecentDocument/RecentDocument';

// Lazy Load...
const Product = React.lazy(() => import('./pages/Products/ProductLine/Product'));

const Recent = React.lazy(() => import('./pages/Recent/RecentlyViewedHome'));

//
// 2021.08.18 - Removing the "homepage":. setting from the package.json, this seems to have fixed
//              the lazy loading issue.
//
// Lazy Loading both of these caused a problem in mid-2021.
// Had to import above.
// Path to load module was being generated incorrectly. It added "/recent" in the path.
// https://inservice.coopersurgical.com/recent/static/js/4.87a58949.chunk.js
//
const RecentVideo = React.lazy(() => import('./pages/Recent/RecentVideo/RecentVideo'));
const RecentDocument = React.lazy(() => import('./pages/Recent/RecentDocument/RecentDocument'));

const ContactUs = React.lazy(() => import('./pages/ContactUs/ContactUs'));




class App extends Component {


    render() {

        return (
            <div>
                <Switch>

                    <RouteWrapper path="/" exact component={Products} layout={Layout} />
                    <RouteWrapper path="/product" exact component={Product} layout={Layout} />

                    <RouteWrapper path="/recent" exact component={Recent} layout={Layout} />
                    <RouteWrapper path="/recent/video" exact component={RecentVideo} layout={Layout} />
                    <RouteWrapper path="/recent/document" exact component={RecentDocument} layout={Layout} />

                    <RouteWrapper path="/contact-us" exact component={ContactUs} layout={Layout} />

                    <Redirect to="/"/>

                </Switch>

                <WistiaManager supportBackdrop={false}/>
                <DocumentDownloadInit/>
            </div>
        );
    }
}



export default App;
