

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};


export const formDate_1 = (d) => {

    const date = d.getDate();
    const month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12.
    const year = d.getFullYear();
    return month + "/" + date + "/" + year;
};






export const buildPhoneLink = (phone, defaultValue) => {

    phone = fixPhoneFormat_1(phone,null);

    if(phone){
        return '<a href="tel:'+phone+'">'+ phone+'</a>';
    }
    return defaultValue;
};


// console.log(fixPhoneFormat_1('1234567'))
// console.log(fixPhoneFormat_1('(123)-456-7890'))
// console.log(fixPhoneFormat_1('1-(234)-567-8901'))
// console.log(fixPhoneFormat_1('908-873-2000 ext 2000'))

export const fixPhoneFormat_1 = (phone, defaultValue) => {

    if( isStringEmpty(phone) ){
        return defaultValue;
    }

    let ph = phone.replace(/\D/g, "");

    if( isStringEmpty(ph) ){
        return defaultValue;
    }

    //console.log(phone);
    //console.log(ph);

    if(ph.length===7){
        // 873-2000
        return ph.substring(0,3)+"-"+ph.substring(3)
    } else if(ph.length===10){
        // 908-873-2000
        return "("+ph.substring(0,3)+")-"+ph.substring(3, 6)+"-"+ph.substring(6);
    } else if(ph.length===11){
        // 1-908-873-2000
        return ph.substring(0,1)+"-("+ph.substring(1, 4)+")-"+ph.substring(4,7)+"-"+ph.substring(7);
    }

    // Display As is
    return phone;

};

// console.log( convertPhoneIntoComponents("123") );
// console.log( convertPhoneIntoComponents("1-234-567-8901") );
// console.log( convertPhoneIntoComponents("123-456-7890") );
// console.log( convertPhoneIntoComponents("(908)-873-2000") );

export const convertPhoneIntoComponents = (phone) => {

    const result = {
        valid:true,
        countryCode:null,
        areaCode:null,
        prefix:null,
        lineNumber:null
    };

    if( isStringEmpty(phone) ){
        return result;
    }

    let ph = phone.replace(/\D/g, "");

    if( isStringEmpty(ph) ){
        return result;
    }


    if(ph.length===7){
        // 873-2000
        result.prefix=ph.substring(0,3);
        result.lineNumber=ph.substring(3);
    } else if(ph.length===10){
        // 908-873-2000
        result.areaCode=ph.substring(0,3);
        result.prefix=ph.substring(3, 6);
        result.lineNumber=ph.substring(6);
    } else if(ph.length===11){
        // 1-908-873-2000
        result.countryCode=ph.substring(0,1);
        result.areaCode=ph.substring(1, 4);
        result.prefix=ph.substring(4,7);
        result.lineNumber=ph.substring(7);
    } else {
        //
        // Invalid Format
        //
        result.valid=false;

        if(ph.charAt(0)==='1'){
            result.countryCode=ph.substring(0,1);
            result.areaCode=ph.substring(1, 4);
            result.prefix=ph.substring(4,7);
            result.lineNumber=ph.substring(7);
        } else {
            result.areaCode=ph.substring(0,3);
            result.prefix=ph.substring(3, 6);
            result.lineNumber=ph.substring(6);
        }
    }

    return result;
};


export const validatePhone = (phone) => {
    let ph = phone.replace(/\D/g, "");

    if( isStringEmpty(ph) ){
        return false;
    }

    if( ph.length===10 || ph.length===11 ){
        return true;
    }

    return false;
};


export const isStringEmpty = (str) => {
    return str==null || str.length === 0 || !str.trim();
};

export const trimString = (str, defaultValue) => {

    if(str==null || str.length === 0 ){
        return defaultValue;
    }

    str = str.trim();

    if( str.length === 0 ){
        return defaultValue;
    }

    return str;
};


export const validZip = (zip) => {

    if(!zip){
        return false;
    }

    // US Zip
    const reUS = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;

    return zip.match(reUS);

    // CA Zip  
    // const reCA = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;

    // return zip.match(reUS) || zip.match(reCA);
};

