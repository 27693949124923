import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();

const generateResourceId = (id) => {
    return PRODUCTS.CERVICAL_RIPENING_BALLOON_WITH_STYLET+"_"+id;
};


const data =
    {
        id: PRODUCTS.CERVICAL_RIPENING_BALLOON_WITH_STYLET,
        alt:"Cervical Ripening Balloon with Stylet",
        title:(<React.Fragment>Cervical Ripening Balloon with Stylet</React.Fragment>),
        productImages: [
            require('../assets/images/cervical-ripening-balloon-stylet/cervical-ripening-balloon-stylet-product.jpg'),
            require('../assets/images/cervical-ripening-balloon-stylet/cervical-ripening-balloon-stylet-product@2x.jpg'),
            require('../assets/images/cervical-ripening-balloon-stylet/cervical-ripening-balloon-stylet-product@3x.jpg')
        ],
        description:'',
        videoData:{
            videoSets:[
                // {
                //     isChapterBased:true,
                //     videos:[
                //         {
                //             resourceId:generateResourceId("vs1v1"),
                //             title:null,
                //             subTitle: "TBD... Overview & Instructions",
                //             description:(<React.Fragment>TBD...</React.Fragment>),
                //             wistiaId:"9t71s5fpz0",
                //             wistiaConfig:{
                //                 padding:"56.25% 0 0 0"
                //             }
                //         }
                //     ]
                //
                // }
            ]
        },
        documentData:{
            documents:[
                {
                    resourceId: generateResourceId("d1"),
                    title:"Cervical Ripening Balloon with Stylet Instructions for Use",
                    description: null,
                    url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/cervical-ripening-balloon-stylet/Cervical Ripening Balloon with Stylet Instructions for Use.pdf",
                    thumbnails:[
                        require("../assets/images/cervical-ripening-balloon-stylet/doc-thumbs/cervical-ripening-balloon-instructions-for-use.jpg"),
                        require("../assets/images/cervical-ripening-balloon-stylet/doc-thumbs/cervical-ripening-balloon-instructions-for-use@2x.jpg"),
                        require("../assets/images/cervical-ripening-balloon-stylet/doc-thumbs/cervical-ripening-balloon-instructions-for-use@3x.jpg")
                    ]
                },

                {
                    resourceId: generateResourceId("d2"),
                    title:"Cervical Ripening Balloon with Stylet Steps of Use Poster",
                    description: null,
                    url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/cervical-ripening-balloon-stylet/Cervical Ripening Balloon with Stylet Steps of Use Poster.pdf",
                    thumbnails:[
                        require("../assets/images/cervical-ripening-balloon-stylet/doc-thumbs/cervical-ripening-balloon-steps-of-use-poster.jpg"),
                        require("../assets/images/cervical-ripening-balloon-stylet/doc-thumbs/cervical-ripening-balloon-steps-of-use-poster@2x.jpg"),
                        require("../assets/images/cervical-ripening-balloon-stylet/doc-thumbs/cervical-ripening-balloon-steps-of-use-poster@3x.jpg")
                    ]
                }
            ]
        },
        externalProductId:""
    };


export default data;








