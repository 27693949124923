import React from 'react';


const Main = (props) => {

    return (

        <div id="main" >
            {props.children}
        </div>
    );

};


export default Main;