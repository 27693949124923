import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.FETAL_PILLOW+"_"+id;
};


const data =
{
    id: PRODUCTS.FETAL_PILLOW,
    alt:"Fetal Pillow",
    title:(<React.Fragment>Fetal Pillow</React.Fragment>),
    productImages: [
            require('../assets/images/fetal-pillow/fetal-pillow.png'),
            require('../assets/images/fetal-pillow/fetal-pillow@2x.png'),
            require('../assets/images/fetal-pillow/fetal-pillow@3x.png')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:null,
                        subTitle:"Steps of use for Fetal Pillow.",
                        description:null,
                        wistiaId:"hkp2v739f3",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"Fetal Pillow Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/Fetal Pillow Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/fetal-pillow-brochure.png"),
                    require("../assets/images/doc-thumbnails/fetal-pillow-brochure@2x.png"),
                    require("../assets/images/doc-thumbnails/fetal-pillow-brochure@3x.png")
                ]
            }
        ]
    },
    externalProductId:"FP-010"
};


export default data;








