import React, {Component} from 'react';

import {APP_VERSION} from '../../config';
import * as serviceWorker from '../../serviceWorker';

import classes from './ServiceWorkerWrapper.module.scss';



class ServiceWorkerWrapper extends Component {

    state = {
        waitingWorker:null,
        displayInstallMessage:false
    };


    componentDidMount(){

        // Keep this code.
        // if(true){
        //     console.log("WARNING: Code is configured to unregister service worker.");
        //     serviceWorker.unregister();
        //     return;
        // }


        console.log("Registering service worker with app version: "+APP_VERSION);

        const swConfig = {
            onUpdate: this.onSWUpdate,

            onSuccess(registration){
                // console.log("onSuccess2", registration);
            }
        };


        serviceWorker.register(swConfig);
    }


    onSWUpdate = (registration) => {

        // console.log("onUpdate2", registration);

        this.setState( {
                displayInstallMessage:true,
                waitingWorker:registration.waiting
            }
        );
    };


    startNewVersion = () => {
        if(this.state.waitingWorker){
            this.state.waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        }

        this.setState( {
                displayInstallMessage:false
            }
        );

        window.location.reload(true);
    };



    hideInstallMessage = () => {

        this.setState( {
                displayInstallMessage:false
            }
        );
    };


    render(){

        let installMessage=null;
        if(this.state.displayInstallMessage){
            installMessage = (
                <div className={classes.ServiceWorkerWrapper}>
                    <div className="container mb-3">
                        <div className="row">
                            <div className="col">
                                <div className={ classes.UpgradeNoticeBox + " text-center"} onClick={this.forceReload}>

                                    <p className="paragraphp3 mb-0">

                                        A new version of the app has been downloaded.
                                        <br/>
                                        <button
                                            className="btn btn-block btn-primary mt-4 mb-3 blue-btn"
                                            onClick={this.startNewVersion}>Start Using The New App</button>
                                        <button
                                            className="btn btn-block btn-primary mt-4 mb-3 blue-btn"
                                            onClick={this.hideInstallMessage}>Close</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }


        return (
            <React.Fragment>
                {installMessage}
             </React.Fragment>
        );
    }
}


export default ServiceWorkerWrapper;