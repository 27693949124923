import React from 'react';

import {getSiteConfig} from '../../config';

//
// This is used to register the remote service work.
// Direct pdf access does not register the service worker so this is needed.
//
// It should be called only once during each opening of the app.
//
const DocumentDownloadInit = () => {

    return (
        <iframe src={getSiteConfig().DOC_SITE_REGISTER_URL}
                title=" "
                frameBorder="0"
                tabIndex="-1"
                width="0"
                height="0"
                style={ {display:"none"} }/>
    )
};


export default DocumentDownloadInit;



