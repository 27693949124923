import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.PARAGARD+"_"+id;
};


const paragardData =
{
    id: PRODUCTS.PARAGARD,
    alt:"Paragard",
    title:(<React.Fragment>Paragard<sup>&reg;</sup></React.Fragment>),
    productImages: [
            require('../assets/images/paragard/paragard-product-img.png'),
            require('../assets/images/paragard/paragard-product-img@2x.png'),
            require('../assets/images/paragard/paragard-product-img@3x.png')
        ],
    description:'',
    videoData:{
        videoSets2:[],
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:"Chapter 1",
                        subTitle: "Preparation",
                        description:(<React.Fragment>Assessing the uterus, cleansing the vagina, aligning the cervical canal, uterine sounding.</React.Fragment>),
                        wistiaId:"uy3zls00ex",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v2"),
                        title:"Chapter 2",
                        subTitle: "Loading",
                        description:(<React.Fragment>Loading Paragard involves tucking the tips of the arms into the loading tube. Two methods are shown: outside packaging using gloves, or within sterile packaging.</React.Fragment>),
                        wistiaId:"hh6lkg0hp3",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v3"),
                        title:"Chapter 3",
                        subTitle: "Placement",
                        description:(<React.Fragment>Inserting loaded Paragard insertion tube, releasing arms from insertion tube, securing Paragard at the fundus, removing stabilizing rod and insertion tube, trimming threads.</React.Fragment>),
                        wistiaId:"sbsajm0plz",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v4"),
                        title:"Chapter 4",
                        subTitle: "Removal",
                        description:(<React.Fragment>Removal of Paragard.</React.Fragment>),
                        wistiaId:"lvojd187ml",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]

            }
        ]
    },
    documentData:{
        documents2:[],
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"Paragard Prescription Information",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/Paragard Prescription Information.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_paragard-information.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_paragard-information@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_paragard-information@3x.jpg")
                ]
            }
        ]
    }
    ,externalProductId:"PARAGARD"
};


export default paragardData;








