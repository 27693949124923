
export const PRODUCTS = {
    PARAGARD: "PARAGARD",
    ENDOSEE_ADVANCE:"ENDOSEE_ADVANCE",
    INSORB:"INSORB",
    RUMI_II_SYSTEM: "RUMI_II_SYSTEM",
    ALLY_UPS: "ALLY_UPS",
    ALLY_II_UPS: "ALLY_II_UPS",
    ADVINCULA_DELINEATOR: "ADVINCULA_DELINEATOR",
    FETAL_PILLOW: "FETAL_PILLOW",
    MILEX_PESSARIES: "MILEX_PESSARIES",
    MARA: "MARA",
    BAKRI_POSTPARTUM_BALLOON: "BAKRI_POSTPARTUM_BALLOON",
    CERVICAL_RIPENING_BALLOON_WITH_STYLET: "CERVICAL_RIPENING_BALLOON_WITH_STYLET"
};


export default PRODUCTS;








