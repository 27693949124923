import React, {Component} from 'react';

import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';

import RetinaImage from '../RetinaImage/RetinaImage';
import * as actions from '../../store/actions';

import classes from './Header.module.scss';
import {lookupProduct, lookupResource} from "../../data/ProductData";

class Header extends Component{


    goHome = () => {

        this.props.onSetHasSystemError(false);
        this.props.onSetPlayingVideo(false,null,null);

        this.props.history.push("/");
    };


    // RJS-2020 - Phase-2 OLD
    // contactUs = () => {
    //     this.props.onSetHasSystemError(false);
    //     this.props.onSetPlayingVideo(false,null,null);
    //
    //     this.props.history.push("/contact-us");
    // };



    // findMyRepExternal = () => {
    //     window.open("https://www.coopersurgical.com/support/locate-a-rep/", '_blank');
    // };


    findMyRep = () => {

        const urlParams = new URLSearchParams(this.props.location.search);
        const productId = urlParams.get('productId');
        let selectedProductId = null;

        if(productId){
            const productConfig = lookupProduct(productId);

            // console.log(productConfig);

            if( productConfig ){
                selectedProductId = productConfig.id;
            }

        } else {
            const resourceId = urlParams.get('resourceId');

            if (resourceId) {

                const resourceConfig = lookupResource(resourceId);

                // console.log(resourceConfig);

                if(resourceConfig) {
                    selectedProductId = resourceConfig.product.id;
                }
            }
        }

        this.props.onSetHasSystemError(false);
        this.props.onSetPlayingVideo(false,null,null);

        if(selectedProductId){
            // window.open("https://www.coopersurgical.com/support/locate-a-rep/?productId="+externalProductId, '_blank');

            this.props.history.push("/contact-us?productId="+selectedProductId);
        } else {
            this.props.history.push("/contact-us");
        }
    };




    render() {

        return (
            <header className={classes.Header}>

                <nav className="navbar fixed-top navbar-expand-md navbar-dark pl-3 pr-0 mb-0 pb-0 pt-0">
                    <div className="container">
                        <button className={classes.LeftBtn}
                                onClick={this.goHome}>
                            <RetinaImage src={[
                                require("../../assets/images/cooper-surgical-r-md-logo-ko.png"),
                                require("../../assets/images/cooper-surgical-r-md-logo-ko@2x.png"),
                                require("../../assets/images/cooper-surgical-r-md-logo-ko@3x.png")]}
                                         alt="Cooper Surgical" />
                        </button>

                        <button className={classes.RightBtn}
                                type="button"
                                onClick={this.findMyRep}>Find My Rep</button>

                    </div>

                </nav>

            </header>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onSetHasSystemError: (systemError) => {
            dispatch( actions.setHasSystemError(systemError) );
        },
        onSetPlayingVideo: (playingVideo, playingVideoId, playingVideoResourceId) => {
            dispatch( actions.setPlayingVideo(playingVideo, playingVideoId, playingVideoResourceId) );
        }
    }

};

export default connect(null, mapDispatchToProps)(withRouter(Header));



