import React from 'react';

import RetinaImage from '../../components/RetinaImage/RetinaImage';
import Main from '../../components/Main/Main';
import UpgradeNoticeAlert from '../../components/UpgradeNoticeAlert/UpgradeNoticeAlert';

import PRODUCTS from '../../data/ProductDataTypes';

import classes from './ProductsHome.module.scss';


const gotoCooperSurgicalSite = () => {
    window.open("https://www.coopersurgical.com/", '_blank');
};

const gotoReimbursementSite = () => {
    window.open("https://www.coopersurgical.com/reimbursement", '_blank');
};




const ProductsHome = (props) => {

    const viewProductLine = (productId) => {
        props.history.push("/product?productId="+productId);
    };

    return (
        <Main>

            <UpgradeNoticeAlert/>

            <div className="container">

                <h2 className="text-center green-blue">Select a Product</h2>

                <div className="row">
                    <div className="col">
                        <p className="paragraphp5 text-center slate-grey">
                            Select a product to access training materials on-demand.
                        </p>
                    </div>
                </div>

                <div className={"row justify-content-center " + classes.SelectProductRow}>
                    <div
                        className="col text-right pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.PARAGARD) }>
                            <RetinaImage src={[
                                require("../../assets/images/paragard/paragard-logo-pwa.jpg"),
                                require("../../assets/images/paragard/paragard-logo-pwa@2x.jpg"),
                                require("../../assets/images/paragard/paragard-logo-pwa@3x.jpg")]}
                                         alt="Paragard" />

                        </div>
                    </div>
                    <div
                        className="col text-left pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.MARA) }>
                            <RetinaImage src={[
                                require("../../assets/images/mara/mara-logo-pwa.jpg"),
                                require("../../assets/images/mara/mara-logo-pwa@2x.jpg"),
                                require("../../assets/images/mara/mara-logo-pwa@3x.jpg")]}
                                         alt="Mara" />
                        </div>
                    </div>
                </div>

                <div className={"row justify-content-center " + classes.SelectProductRow}>
                    <div
                        className="col text-right pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.ENDOSEE_ADVANCE) }>
                            <RetinaImage src={[
                                require("../../assets/images/endosee/endosee-logo-pwa.jpg"),
                                require("../../assets/images/endosee/endosee-logo-pwa@2x.jpg"),
                                require("../../assets/images/endosee/endosee-logo-pwa@3x.jpg")]}
                                         alt="Endosee" />
                        </div>
                    </div>
                    <div
                        className="col text-left pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.INSORB) }>
                            <RetinaImage src={[
                                require("../../assets/images/insorb/insorb-logo-pwa.jpg"),
                                require("../../assets/images/insorb/insorb-logo-pwa@2x.jpg"),
                                require("../../assets/images/insorb/insorb-logo-pwa@3x.jpg")]}
                                         alt="Insorb" />
                        </div>
                    </div>
                </div>

                <div className={"row justify-content-center " + classes.SelectProductRow}>
                    <div
                        className="col text-right pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.RUMI_II_SYSTEM) }>
                            <RetinaImage src={[
                                require("../../assets/images/rumi-II/rumi-logo-pwa.jpg"),
                                require("../../assets/images/rumi-II/rumi-logo-pwa@2x.jpg"),
                                require("../../assets/images/rumi-II/rumi-logo-pwa@3x.jpg")]}
                                         alt="Rumi II System" />
                        </div>
                    </div>
                    <div
                        className="col text-left pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.ADVINCULA_DELINEATOR) }>
                            <RetinaImage src={[
                                require("../../assets/images/advincula/advincula-logo-pwa.jpg"),
                                require("../../assets/images/advincula/advincula-logo-pwa@2x.jpg"),
                                require("../../assets/images/advincula/advincula-logo-pwa@3x.jpg")]}
                                         alt="Advincula" />
                        </div>
                    </div>
                </div>

                <div className={"row justify-content-center " + classes.SelectProductRow}>
                    <div
                        className="col text-right pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.ALLY_UPS) }>
                            <RetinaImage src={[
                                require("../../assets/images/ally-ups/ally-logo-pwa.jpg"),
                                require("../../assets/images/ally-ups/ally-logo-pwa@2x.jpg"),
                                require("../../assets/images/ally-ups/ally-logo-pwa@3x.jpg")]}
                                         alt="Ally UPS" />
                        </div>
                    </div>
                    <div
                        className="col text-left pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.ALLY_II_UPS) }>
                            <RetinaImage src={[
                                require("../../assets/images/ally-II-ups/ally-II-logo-pwa.jpg"),
                                require("../../assets/images/ally-II-ups/ally-II-logo-pwa@2x.jpg"),
                                require("../../assets/images/ally-II-ups/ally-II-logo-pwa@3x.jpg")]}
                                         alt="Ally II UPS" />
                        </div>
                    </div>
                </div>

                <div className={"row justify-content-center " + classes.SelectProductRow}>
                    <div
                        className="col text-right pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.MILEX_PESSARIES) }>
                            <RetinaImage src={[
                                require("../../assets/images/milex-pessaries/milex-logo-pwa.jpg"),
                                require("../../assets/images/milex-pessaries/milex-logo-pwa@2x.jpg"),
                                require("../../assets/images/milex-pessaries/milex-logo-pwa@3x.jpg")]}
                                         alt="Milex Pessaries" />
                        </div>
                    </div>
                    <div
                        className="col text-left pr-0 pl-0">
                        <div className={classes.SelectProduct}
                             onClick={ () => viewProductLine(PRODUCTS.FETAL_PILLOW) }>
                            <RetinaImage src={[
                                require("../../assets/images/fetal-pillow/fetal-pillow-logo-pwa.jpg"),
                                require("../../assets/images/fetal-pillow/fetal-pillow-logo-pwa@2x.jpg"),
                                require("../../assets/images/fetal-pillow/fetal-pillow-logo-pwa@3x.jpg")]}
                                         alt="Ally UPS" />
                        </div>
                    </div>
                </div>




                {/*<div className={"row justify-content-center " + classes.SelectProductRow}>*/}
                {/*    <div*/}
                {/*        className="col text-right pr-0 pl-0">*/}
                {/*        <div className={classes.SelectProduct}*/}
                {/*             onClick={ () => viewProductLine(PRODUCTS.BAKRI_POSTPARTUM_BALLOON) }>*/}
                {/*            <RetinaImage src={[*/}
                {/*                require("../../assets/images/bakri-postpartum-balloon/bakri-postpartum-balloon-logo.jpg"),*/}
                {/*                require("../../assets/images/bakri-postpartum-balloon/bakri-postpartum-balloon-logo@2x.jpg"),*/}
                {/*                require("../../assets/images/bakri-postpartum-balloon/bakri-postpartum-balloon-logo@3x.jpg")]}*/}
                {/*                         alt="Bakri Postpartum Balloon" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className="col text-left pr-0 pl-0">*/}
                {/*        <div className={classes.SelectProduct}*/}
                {/*             onClick={ () => viewProductLine(PRODUCTS.CERVICAL_RIPENING_BALLOON_WITH_STYLET) }>*/}
                {/*            <RetinaImage src={[*/}
                {/*                require("../../assets/images/cervical-ripening-balloon-stylet/cervical-ripening-balloon-stylet-logo.jpg"),*/}
                {/*                require("../../assets/images/cervical-ripening-balloon-stylet/cervical-ripening-balloon-stylet-logo@2x.jpg"),*/}
                {/*                require("../../assets/images/cervical-ripening-balloon-stylet/cervical-ripening-balloon-stylet-logo@3x.jpg")]}*/}
                {/*                         alt="Cervical Ripening Balloon with Stylet" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}



                <div className={classes.bottomSection}>
                    <div className={classes.buttonSection}>
                        <button
                            className={"btn btn-primary mb-3 blue-btn "+classes.space}
                            onClick={gotoReimbursementSite}>Reimbursement Information</button>
                        <button
                            className="btn btn-primary mb-3 blue-btn"
                            onClick={gotoCooperSurgicalSite}>CooperSurgical Site</button>
                    </div>
                </div>
            </div>
        </Main>
    );
}


export default ProductsHome;
