import {APP_VERSION, getSiteConfig} from '../config';



export const getVersionDetails = () => {

    return fetch(getSiteConfig().GET_APP_VERION_SERVICE_URL,
        {
            method:"GET",
            headers: {
                'Accept':'application/json'
            }
        })
        .then( (response) => {

            return response.json();
        })
        .then( (json) => {

            // console.log("version json",json);

            return {
                status:1,
                versionDetails: json,
                isAppLatestVersion: json.version === APP_VERSION
            }
        })
        .catch( (err) => {
            return {
                status: -1,
                error:err
            }
        })
};



