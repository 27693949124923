import * as actionTypes from '../actions/actionTypes'

import {updateObject} from '../../shared/utility';


const initialState = {

    hasSystemError: false,
    playingVideo: false,
    playingVideoId: null,
    playingVideoResourceId: null
};


const reducer = (state=initialState, action) => {

    switch(action.type){

        case actionTypes.SET_HAS_SYSTEM_ERROR:
            return updateObject(state, {
                hasSystemError:action.hasSystemError
            });


        case actionTypes.SET_PLAYING_VIDEO:
            return updateObject(state, {
                playingVideo:action.playingVideo,
                playingVideoId: action.playingVideoId,
                playingVideoResourceId: action.playingVideoResourceId
            });

        default:
            return state;
    }
};

export default reducer;