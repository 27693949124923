import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


export const generateResourceId = (id) => {
    return PRODUCTS.MILEX_PESSARIES+"_"+id;
};


const data =
{
    id: PRODUCTS.MILEX_PESSARIES,
    alt:"Milex Pessaries",
    title:(<React.Fragment>Milex Pessaries</React.Fragment>),
    productImages: [
            require('../assets/images/milex-pessaries/milex-pessaries-product.jpg'),
            require('../assets/images/milex-pessaries/milex-pessaries-product@2x.jpg'),
            require('../assets/images/milex-pessaries/milex-pessaries-product@3x.jpg')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:"Milex Hodge Pessary",
                        subTitle:"Animation demonstrating insertion of Hodge Pessary for Stress Urinary Incontinence.",
                        description:null,
                        wistiaId:"mp0ws6sd9n",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v2"),
                        title:"Milex Shaatz Pessary",
                        subTitle:"Animation demonstrating insertion of Shaatz Pessary for 1st and 2nd degree pelvic organ prolapse.",
                        description:null,
                        wistiaId:"k4jshhuxx2",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v3"),
                        title:"Milex Ring Pessary",
                        subTitle:"Animation demonstrating insertion of Ring Pessary for 1st and 2nd degree pelvic organ prolapse.",
                        description:null,
                        wistiaId:"ql3v04jdrn",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v4"),
                        title:"Milex Gellhorn Pessary",
                        subTitle:"Animation demonstrating insertion of Gellhorn Pessary for 3rd degree pelvic organ prolapse/procidentia.",
                        description:null,
                        wistiaId:"l3etlew8at",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v5"),
                        title:"Milex Gehrung Pessary",
                        subTitle:"Animation demonstrating insertion of Gehrung Pessary for uterine prolapse support, effective support for both cystocele and rectocele.",
                        description:null,
                        wistiaId:"olga3nl5ib",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v6"),
                        title:"Milex Donut Pessary",
                        subTitle:"Animation demonstrating insertion of Donut Pessary for 3rd degree pelvic organ prolapse/procidentia.",
                        description:null,
                        wistiaId:"2edorccbav",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v7"),
                        title:"Milex Cube Pessary",
                        subTitle:"Animation demonstrating insertion of Cube Pessary for 3rd degree pelvic organ prolapse/procidentia.",
                        description:null,
                        wistiaId:"fgu5w5laya",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            },
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v8"),
                        title:"Milex Inflatoball Pessary",
                        subTitle:"Animation demonstrating insertion of Inflatoball Pessary for 2nd and 3rd degree pelvic organ prolapse/procidentia.",
                        description:null,
                        wistiaId:"4r61vredmk",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            }

        ]
    },
    documentData:{
        documents:[
            // {
            //     resourceId: generateResourceId("d1"),
            //     title:"Pessary Reimbursement Guide 2022",
            //     description:null,
            //     url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Pessary-Reimbursement-Guide-2022.pdf",
            //     thumbnails:[
            //         require("../assets/images/milex-pessaries/doc-thumbs/Pessary Reimbursement Guide 2021.jpg"),
            //         require("../assets/images/milex-pessaries/doc-thumbs/Pessary Reimbursement Guide 2021@2x.jpg"),
            //         require("../assets/images/milex-pessaries/doc-thumbs/Pessary Reimbursement Guide 2021@3x.jpg")
            //     ]
            // },
            {
                resourceId: generateResourceId("d2"),
                title:"Milex Cube Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Cube Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Cube Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Cube Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Cube Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d3"),
                title:"Milex Dish Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Dish Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Dish Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Dish Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Dish Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d4"),
                title:"Milex Donut Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Donut Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Donut Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Donut Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Donut Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d5"),
                title:"Milex Gehrung Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Gehrung Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Gehrung Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Gehrung Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Gehrung Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d6"),
                title:"Milex Gellhorn Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Gellhorn Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Gellhorn Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Gellhorn Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Gellhorn Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d7"),
                title:"Milex Hodge Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Hodge Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Hodge Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Hodge Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Hodge Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d8"),
                title:"Milex Inflatoball Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Inflatoball Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Inflatoball Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Inflatoball Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Inflatoball Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d9"),
                title:"Milex Regula Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Regula Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Regula Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Regula Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Regula Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d10"),
                title:"Milex Ring Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Ring Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Ring Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Ring Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Ring Pessary Instructions for Use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d11"),
                title:"Milex Shaatz Pessary Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/milex-pessaries/Milex Shaatz Pessary Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Shaatz Pessary Instructions for Use.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Shaatz Pessary Instructions for Use@2x.jpg"),
                    require("../assets/images/milex-pessaries/doc-thumbs/Milex Shaatz Pessary Instructions for Use@3x.jpg")
                ]
            }
        ]
    },
    externalProductId:"MXKPEC00"
};


export default data;








