import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();

const generateResourceId = (id) => {
    return PRODUCTS.ADVINCULA_DELINEATOR+"_"+id;
};


const data =
{
    id: PRODUCTS.ADVINCULA_DELINEATOR,
    alt:"Advincula Delineator Uterine Manipulator",
    title:(<React.Fragment>Advincula Delineator<sup>&trade;</sup> Uterine Manipulator</React.Fragment>),
    productImages: [
            require('../assets/images/advincula/advincula-product-img.png'),
            require('../assets/images/advincula/advincula-product-img@2x.png'),
            require('../assets/images/advincula/advincula-product-img@3x.png')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:null,
                        subTitle: "Overview & Instructions",
                        description:(<React.Fragment>Brief overview including available sizes and cup types. Procedure guide includes cervical sizing, use of Koh-Efficient&reg; system, uterine tip and pneumo-occluder balloons.</React.Fragment>),
                        wistiaId:"9t71s5fpz0",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]

            }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"Advincula Delineator Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/Advincula Delineator Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_advincula-for-use.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_advincula-for-use@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_advincula-for-use@3x.jpg")
                ]
            },

            {
                resourceId: generateResourceId("d2"),
                title:"Advincula Delineator Soft Cup Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/Advincula Delineator Soft Cup Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_advincula-soft-cup.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_advincula-soft-cup@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_advincula-soft-cup@3x.jpg")
                ]
            }
        ]
    },
    externalProductId:"AD750-KE25"
};


export default data;








