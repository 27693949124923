import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.RUMI_II_SYSTEM+"_"+id;
};


const data =
{
    id: PRODUCTS.RUMI_II_SYSTEM,
    alt:"RUMI II SYSTEM",
    title:(<React.Fragment>RUMI<sup>&reg;</sup> II SYSTEM</React.Fragment>),
    productImages: [
            require('../assets/images/rumi-II/rumi-product-img.png'),
            require('../assets/images/rumi-II/rumi-product-img@2x.png'),
            require('../assets/images/rumi-II/rumi-product-img@3x.png')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:"Chapter 1",
                        subTitle: "System Overview & General Operation",
                        description:(<React.Fragment>System components, available tip sizes, cup sizes and types, handle operation.</React.Fragment>),
                        wistiaId:"qcyj4kj5iy",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v2"),
                        title:"Chapter 2",
                        subTitle: "Procedure Guide",
                        description:(<React.Fragment>Selecting and attaching correct-sized tip, measuring cervical size, attaching Koh-Efficient®, insertion, removal, disassembly, cleaning.</React.Fragment>),
                        wistiaId:"7gcjd4ypv8",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]
            }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"RUMI II Handle Instructions for Use",
                description:null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/RUMI II Handle Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-handle.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-handle@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-handle@3x.jpg")
                ]
            },

            {
                resourceId: generateResourceId("d2"),
                title:"RUMI II Koh-Efficient Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/RUMI II Koh Efficient Directions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-koh.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-koh@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-koh@3x.jpg")
                ]
            },

            {
                resourceId: generateResourceId("d3"),
                title:"RUMI II Tips Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/RUMI II Tips Directions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-tips.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-tips@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_rumi-ii-tips@3x.jpg")
                ]
            }
        ]
    },
    externalProductId:"KC-RUMI-30"
};


export default data;








