import React, {Component} from 'react';

import Spinner from '../../components/UI/Spinner/Spinner';

import {getVersionDetails} from '../../shared/version';

import classes from './UpgradeNoticeAlert.module.scss';



class UpgradeNoticeAlert extends Component {

    state = {
        displaySpinnerOnUpdate: false,
        displayUpgradeAlert:false
    };


    componentDidMount(){

        getVersionDetails()
            .then( (json) => {

                // console.log(json);

                if( json.status===1 && !json.isAppLatestVersion){
                    this.setState( {
                        displaySpinnerOnUpdate: false,
                        displayUpgradeAlert:true

                    });
                } else {
                    this.setState( {
                        displaySpinnerOnUpdate: false,
                        displayUpgradeAlert:false
                    });
                }

            } );
    }



    // DOES NOT WORK!!!!
    // swUpdate = () => {
    //     if ('serviceWorker' in navigator) {
    //         navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //             for (let registration of registrations) {
    //                 registration.update()
    //             }
    //         })
    //     }
    //
    //     window.location.reload(true);
    // };

    forceReload = () => {

        this.setState( {
            displaySpinnerOnUpdate:true
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then((registrations) =>
                    Promise.all(registrations.map((r) => {
                        return r.unregister();
                    })),
                )
                .then(() => {
                    window.location.reload(true);
                })
        }
    };



    render(){

        let displayUpgradeAlert=null;
        if(this.state.displayUpgradeAlert){
            displayUpgradeAlert = (
                <div className="container mb-3">
                    <div className="row">
                        <div className="col">
                            <div className={ classes.UpgradeNoticeBox + " text-center"} onClick={this.forceReload}>
                                <p className="paragraphp3 mb-0">
                                    A new version of the app is now available.
                                    <br/>Click here to download the latest version.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let displaySpinnerOnUpdate = null;
        if(this.state.displaySpinnerOnUpdate){
            displaySpinnerOnUpdate = <Spinner/>;
        }


        return (
            <React.Fragment>

                {/*<p className="text-center">RJS: App Version:{APP_VERSION}</p>*/}

                {displaySpinnerOnUpdate}

                {displayUpgradeAlert}

            </React.Fragment>
        );
    }
}


export default UpgradeNoticeAlert;