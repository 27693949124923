import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from "../../store/actions";
import classes from './ControlBar.module.scss';


const BUTTON = {
    PRODUCTS:1,
    RECENT:2
};



class ControlBar extends Component {

    state = {
        selected:BUTTON.PRODUCTS
    };


    componentDidMount() {
        this.determineSelectedBasedOnPath();
    }



    componentDidUpdate(previousProps) {

        // console.log("this.props.location", this.props.location)
        // console.log("previousProps.location", previousProps.location)

        if (previousProps.location.pathname !== this.props.location.pathname) {

            this.determineSelectedBasedOnPath();
        }
    }


    determineSelectedBasedOnPath = () => {

        if( this.props.location.pathname.startsWith("/recent")){
            this.setState( {selected:BUTTON.RECENT} );
        } else {
            this.setState( {selected:BUTTON.PRODUCTS} );
        }
    };



    viewProducts = () => {

        this.setState( {selected:BUTTON.PRODUCTS} );

        this.props.onSetHasSystemError(false);
        this.props.onSetPlayingVideo(false,null,null);

        this.props.history.push("/");
    };



    viewRecent = () => {

        this.setState( {selected:BUTTON.RECENT} );

        this.props.onSetHasSystemError(false);
        this.props.onSetPlayingVideo(false,null,null);

        this.props.history.push("/recent");
    };



    render() {

        return (
            <div className= { "fixed-bottom " + classes.ControlBar} >
                <div className={ "container " + classes.ControlBarBtnPanel} >
                    <button
                        className={"mb-3 " + (this.state.selected===BUTTON.PRODUCTS?classes.SelectedBtn:'')}
                        onClick={this.viewProducts}><i className={ classes.FasCustom + " fas fa-medkit"} ></i><div className={classes.BtnText}>Products</div></button>
                    <button
                        className={"mb-3 "+ (this.state.selected===BUTTON.RECENT?classes.SelectedBtn:'')}
                        onClick={this.viewRecent}><i className={ classes.FasCustom + " fas fa-eye"}></i><div className={classes.BtnText}>Recent</div></button>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onSetHasSystemError: (systemError) => {
            dispatch( actions.setHasSystemError(systemError) );
        },
        onSetPlayingVideo: (playingVideo, playingVideoId, playingVideoResourceId) => {
            dispatch( actions.setPlayingVideo(playingVideo, playingVideoId, playingVideoResourceId) );
        }
    }

};

export default connect(null, mapDispatchToProps)(withRouter(ControlBar));

