import React, {Suspense} from 'react';

import Header from '../../components/Header/Header';
import ControlBar from '../../components/ControlBar/ControlBar';
import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';
import Spinner from '../../components/UI/Spinner/Spinner';
import Footer from '../../components/Footer/Footer';



const Layout = (props) => {

    return (
        <React.Fragment>
            <Header/>

            <ErrorBoundary>
                <Suspense fallback={<Spinner/>} maxDuration={1000}>
                    {props.children}
                </Suspense>
            </ErrorBoundary>

            <Footer/>

            <ControlBar/>
        </React.Fragment>
    );
};


export default Layout;