import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.ALLY_2_UPS+"_"+id;
};


const data =
{
    id: PRODUCTS.ALLY_II_UPS,
    alt:"ALLY II UPS®",
    title:(<React.Fragment>ALLY II UPS<sup>&reg;</sup></React.Fragment>),
    productImages: [
            require('../assets/images/ally-II-ups/ally-II-ups-image.png'),
            require('../assets/images/ally-II-ups/ally-II-ups-image@2x.png'),
            require('../assets/images/ally-II-ups/ally-II-ups-image@3x.png')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:"Chapter 1",
                        subTitle: "Overview & Procedure Setup",
                        description:null,
                        // description:(<React.Fragment>Brief overview, includes mounting and positioning on operating table, attaching adapter drapes for use with RUMI&reg; II, Advincula Arch&trade; or Advincula Delineator&trade; uterine manipulators.</React.Fragment>),
                        wistiaId:"fdnuymjxek",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v2"),
                        title:"Chapter 2",
                        subTitle: "Procedure Guide",
                        description:null,
                        // description:(<React.Fragment>Instructions for attaching the arm of the UPS to the uterine manipulator, detaching, cleaning and storage.</React.Fragment>),
                        wistiaId:"kz2t5rqgwk",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]

            }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"ALLY II UPS Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/ally-II-ups/ALLY II UPS IFU - Final.pdf",
                thumbnails:[
                    require("../assets/images/ally-II-ups/doc-thumbs/all-II-ups-ifu.jpg"),
                    require("../assets/images/ally-II-ups/doc-thumbs/all-II-ups-ifu@2x.jpg"),
                    require("../assets/images/ally-II-ups/doc-thumbs/all-II-ups-ifu@3x.jpg")
                ]
            }
        ]
    },
    externalProductId:"AU-UPS2"
};


export default data;








