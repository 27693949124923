import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.ALLY_UPS+"_"+id;
};


const data =
{
    id: PRODUCTS.ALLY_UPS,
    alt:"ALLY Uterine Positioning System",
    title:(<React.Fragment>ALLY Uterine Positioning System<sup>&reg;</sup></React.Fragment>),
    productImages: [
            require('../assets/images/ally-ups/ally-ups-product-img.png'),
            require('../assets/images/ally-ups/ally-ups-product-img@2x.png'),
            require('../assets/images/ally-ups/ally-ups-product-img@3x.png')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId:generateResourceId("vs1v1"),
                        title:"Chapter 1",
                        subTitle: "Overview, Setup for Procedure",
                        description:(<React.Fragment>Brief overview, includes mounting and positioning on operating table, attaching adapter drapes for use with RUMI&reg; II, Advincula Arch&trade; or Advincula Delineator&trade; uterine manipulators.</React.Fragment>),
                        wistiaId:"d93n8iyzsu",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v2"),
                        title:"Chapter 2",
                        subTitle: "Procedure Guide",
                        description:(<React.Fragment>Instructions for attaching the arm of the UPS to the uterine manipulator, detaching, cleaning and storage.</React.Fragment>),
                        wistiaId:"d9ec113z14",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]

            }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"ALLY Uterine Positioning System Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/ALLY Uterine Positioning System Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-for-use.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-for-use@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-for-use@3x.jpg")
                ]
            },

            {
                resourceId: generateResourceId("d2"),
                title:"ALLY Uterine Positioning System Adapter Drape Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/ALLY UPS Adapter Drape Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-adapter.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-adapter@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-adapter@3x.jpg")
                ]
            },

            {
                resourceId: generateResourceId("d3"),
                title:"ALLY Uterine Positioning System Adapter Drape for Advincula Delineator Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/ALLY UPS Drape for Advincula Delineator Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-drape.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-drape@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-drape@3x.jpg")
                ]
            },

            {
                resourceId: generateResourceId("d4"),
                title:"ALLY Uterine Positioning System Cart Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/ALLY UPS Cart Instructions for Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-cart.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-cart@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_ally-ups-cart@3x.jpg")
                ]
            }
        ]
    },
    externalProductId:"AU-UPS"
};


export default data;








