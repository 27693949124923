import React, {Component} from 'react';
import {connect} from 'react-redux';

import {addRecentlyViewed} from '../../pages/Recent/recently-viewed-utils';
import * as actions from "../../store/actions";

import * as firebase from "firebase/app";
import "firebase/analytics";

// import CustomFullScreenButton from './CustomFullScreenButton';

import classes from './WistiaManager.module.scss';




class WistiaManager extends Component{


    constructor(props) {
        super(props);

        this.initWistiaGlobalUtils();
    }


    initWistiaGlobalUtils = () => {

        if (!document.getElementById("wistia_script") ) {

            //
            // Load E-v1.js
            //
            const wistiaScript = document.createElement("script");
            wistiaScript.id = "wistia_script";
            wistiaScript.type = "text/javascript";
            wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
            wistiaScript.async = true;
            document.body.appendChild(wistiaScript);
        }


        window._wq = window._wq || [];


        if(window._d2WistiaInitProcessed){
            return;
        }

        window._d2WistiaInitProcessed=true;


        //
        // Load custom buttons or plugins.
        //
        // window.wistiaInitQueue = window.wistiaInitQueue || [];
        // window.wistiaInitQueue.push( (W) => {
        //     window.Wistia.defineControl(CustomFullScreenButton);
        // });


        //
        // Only run this once.
        //
        window._wq.push({

            id: "_all",

            onReady: (video) => {

                // console.log("global-onready", video.hashedId());


                video.bind("play", () => {

                    // console.log("Playing:", video.hashedId());

                    const allVideos = window.Wistia.api.all();
                    for (let i = 0; i < allVideos.length; i++) {

                        // console.log(allVideos[i].hashedId())

                        if (allVideos[i].hashedId() !== video.hashedId()) {
                            allVideos[i].pause();
                        }
                    }

                    const resourceId = video.container.getAttribute("resourceid");
                    // console.log(resourceId);

                    if(resourceId){

                        addRecentlyViewed(resourceId);
                    }

                    this.props.onSetPlayingVideo(true, video.hashedId(), resourceId);

                    firebase.analytics().logEvent('view_content', {
                        content_type: 'video',
                        content_id: resourceId,
                        wistia_code: video.hashedId()
                    });

                });


                video.bind("pause", () => {
                    // console.log("The video was just paused!");

                    this.props.onSetPlayingVideo(false, null, null);
                });

                video.bind("end", () => {
                    // console.log("The video ended!", video.time(), video.duration(), video.length);

                    // video.time( 0 );
                    this.props.onSetPlayingVideo(false, null, null);
                });
            }
        });
    };



    render() {

        if( this.props.supportBackdrop ){
            const classArray = [classes.Backdrop];
            return (
                this.props.playingVideo ? <div className={classArray} onClick={this.props.clicked}></div> : null
            );
        }


        // No backdrop
        return null;
    }
}


const mapStateToProps = (state) => {
    return {
        playingVideo: state.misc.playingVideo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPlayingVideo: (playingVideo, playingVideoId, playingVideoResourceId) => {
            dispatch( actions.setPlayingVideo(playingVideo, playingVideoId, playingVideoResourceId) );
        }
    }

};


export default connect(mapStateToProps, mapDispatchToProps)(WistiaManager);



