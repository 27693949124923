import {openDb} from "idb/lib/idb";
import {is_Explorer} from '../shared/browser-detection';



export const supportsIndexedDB = () => {

    if(is_Explorer()){
        //
        // Bug in IE 11 is preventing readAll() from working.
        //
        return false;
    }

    return ('indexedDB' in window);
};


const dbPromise = openDb('poc-store', 1, upgradeDB => {

    if (!upgradeDB.objectStoreNames.contains('recentlyviewed')) {
        upgradeDB.createObjectStore('recentlyviewed', {keyPath:"resourceId"});
    }
});


export const writeData = (st, data) => {

    return dbPromise
        .then( (db) => {

            const tx = db.transaction(st, 'readwrite');
            const store = tx.objectStore(st);
            store.put(data);
            return tx.complete;
        });
};

export const readAllData = (st) => {

    return dbPromise
        .then( (db) => {
            const tx = db.transaction(st, 'readonly');
            const store = tx.objectStore(st);
            return store.getAll();
        });
};



export const clearAllData = (st) => {
    return dbPromise
        .then( (db) => {
            const tx = db.transaction(st, 'readwrite');
            const store = tx.objectStore(st);
            store.clear();
            return tx.complete;
        });
};

export const deleteItemFromData = (st, id) => {
    dbPromise
        .then( (db) => {
            const tx = db.transaction(st, 'readwrite');
            const store = tx.objectStore(st);
            store.delete(id);
            return tx.complete;
        });
};