import paragardData from './ParagardData';
import endoseeAdvanceData from './EndoseeAdvanceData';
import insorbData from './InsorbData';
import rumiIISystemData from './RumIISystemData';
import advinculaDelineatorData from './AdvinculaDelineatorData';
import allyUpsData from './AllyUpsData';
import ally_II_UpsData from './Ally_II_UpsData';
import fetalPillowData from './FetalPillowData';
import milexPessariesData from './MilexPessariesData';
import maraData from './MaraData';
import bakriPostpartumBallonData from './BakriPostpartumBallon';
import cervicalRipeningBalloonStyletData from './CervicalRipeningBalloonWithStylet';

const productData = [
    paragardData,
    endoseeAdvanceData,
    insorbData,
    rumiIISystemData,
    advinculaDelineatorData,
    allyUpsData,
    ally_II_UpsData,
    fetalPillowData,
    milexPessariesData,
    maraData,
    bakriPostpartumBallonData,
    cervicalRipeningBalloonStyletData
];


//
// Mapped to resourceId:
//  value:
//      {
//          resourceId:'abc';
//          video:
//          document:
//          product:
//      }
//
const resourceToIdMap = {};

const mapResourceIdToResource = () => {


    productData.forEach((product) => {

        // console.log(product);

        // Map Documents
        product.documentData.documents.forEach((document) => {

            resourceToIdMap[document.resourceId] =
                {
                    document:document,
                    product:product
                };
        }); // End document forEach


        // Map Videos
        product.videoData.videoSets.forEach((videoSet) => {

            videoSet.videos.forEach( (video) => {

                resourceToIdMap[video.resourceId] =
                    {
                        video:video,
                        videoSet:videoSet,
                        product:product
                    };

            }); // End VideoSets
        }); // End document forEach

    }); // End product forEach

};


// Initialize Maps.
mapResourceIdToResource();

// console.log("resourceToIdMap:", resourceToIdMap);



export const lookupResource = (id) => {

    return resourceToIdMap[id];
};






export const lookupProduct = (id) => {

    return productData.find( element => element.id===id );
};





export default productData;








