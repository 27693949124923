import React from 'react';

import classes from './Spinner.module.css'

const spinner = () => {
    return (
        <div id="main" style={{height:"100%"}}>
            <div className={classes.center}>
                <div className={classes.Loader}>Loading...</div>
            </div>
        </div>
    );
};

export default spinner;